<template>
  <v-menu v-if="range" offset-y v-model="menu" :close-on-content-click="false">
    <template #activator="{ on, attrs }">
      <v-btn
        v-show="!hide"
        ref="share_enabled"
        depressed
        tile
        width="64"
        max-height="92"
        class="grey--text text--darken-2"
        v-bind="attrs"
        v-on="on"
        data-draft-action-button="share"
      >
        <div style="height: 56px">
          <v-icon>mdi-link-variant</v-icon>
          <div
            style="line-height: normal"
            class="pt-1 text-none text-caption"
            v-text="$t('share')"
          />
        </div>
      </v-btn>
    </template>
    <v-card class="pa-4" width="330" data-draft-action-form="share">
      <div class="pb-4 text-body-2" v-text="$t('share instructions')" />
      <v-text-field
        outlined
        dense
        readonly
        hide-details
        @click="(ev) => ev.target.select()"
        :value="url"
        :disabled="!isPublic"
        :loading="!isPublic"
      />
    </v-card>
  </v-menu>

  <v-tooltip v-else bottom>
    <template #activator="{ on, attrs }">
      <v-card
        v-show="!hide"
        ref="share_disabled"
        flat
        tile
        color="transparent"
        width="64"
        max-height="92"
        class="d-flex align-center justify-center"
        v-on="on"
        v-bind="attrs"
      >
        <v-card
          color="transparent"
          class="grey--text"
          flat
          tile
          height="56"
          disabled
        >
          <v-icon color="grey">mdi-link-variant</v-icon>
          <div
            style="line-height: normal"
            class="pt-1 text-none text-caption"
            v-text="$t('share')"
          />
        </v-card>
      </v-card>
    </template>
    <div class="text-caption" v-text="$t('sharing disabled')" />
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ScheduleShare",
  props: {
    hide: { type: Boolean },
  },
  data: () => ({
    menu: false,
  }),
  watch: {
    menu(ok) {
      if (ok && !this.isPublic) this.$emit("enable");
    },
  },
  computed: {
    ...mapGetters({
      range: "draft/range",
      isPublic: "draft/isPublic",
    }),
    url() {
      const route = this.$router.resolve(
        this.$route.matched.find((r) => r.meta.public).meta.public(this.$route)
      );
      return window.location.origin + route.href;
    },
  },
};
</script>
