<template>
  <Layout active="times">
    <template #title>
      <ScheduleRename
        v-if="isRenaming"
        @close="isRenaming = false"
        @change="setTitle"
        data-draft-action-form="rename"
      />

      <ScheduleDates
        v-else-if="isSettingDates"
        v-bind="range"
        @close="isSettingDates = false"
        @change="setRange"
        data-draft-action-form="dates"
      />

      <ScheduleSelect v-show="!isEditing" :data-draft-title="title" />
    </template>

    <template #navigation>
      <ViewToggle v-show="!isEditing" />
    </template>

    <template #actions>
      <!-- rename schedule -->
      <v-btn
        ref="rename"
        v-show="!isEditing"
        depressed
        tile
        width="64"
        max-height="92"
        class="grey--text text--darken-2"
        @click="isRenaming = true"
        data-draft-action-button="rename"
      >
        <div style="height: 56px">
          <v-icon>mdi-rename-box</v-icon>
          <div style="line-height: normal" class="pt-1 text-none text-caption">
            {{ $t("rename") }}
          </div>
        </div>
      </v-btn>

      <!--set dates-->
      <v-btn
        ref="setDates"
        v-show="!isEditing"
        depressed
        tile
        width="64"
        max-height="92"
        class="grey--text text--darken-2"
        @click="isSettingDates = true"
        data-draft-action-button="dates"
      >
        <div style="height: 56px">
          <v-icon>mdi-calendar-range</v-icon>
          <div style="line-height: normal" class="pt-1 text-none text-caption">
            {{ $t("set dates") }}
          </div>
        </div>
      </v-btn>

      <!--share schedule-->
      <ScheduleShare @enable="enableSharing" :hide="isEditing" />
    </template>

    <router-view
      ref="view"
      @update:week="(week) => $router.push({ query: { week } })"
      @updateSeries="updateClassSeries"
      @deleteSeries="deleteClassSeries"
      @setInstance="setClassInstance"
      @unsetInstance="unsetClassInstance"
    />
  </Layout>
</template>

<script>
import { deleteField, where, doc, getDoc } from "@firebase/firestore";
import { nanoid } from "nanoid";
import moment from "moment";
import { mapGetters } from "vuex";
import Layout from "@/components/draft/Layout";
import ScheduleSelect from "@/components/draft/ScheduleSelect";
import ScheduleRename from "@/components/draft/ScheduleRename";
import ScheduleDates from "@/components/draft/ScheduleDates";
import ScheduleShare from "@/components/draft/ScheduleShare";
import ViewToggle from "@/components/draft/ViewToggle";
export default {
  name: "Draft",
  components: {
    Layout,
    ScheduleSelect,
    ScheduleRename,
    ScheduleDates,
    ScheduleShare,
    ViewToggle,
  },
  data: () => ({
    draftRef: null,
    week: null,
    isRenaming: false,
    isSettingDates: false,
    holidayUnsubscribe: null,
  }),
  computed: {
    ...mapGetters({
      title: "draft/title",
      template: "draft/template",
      range: "draft/range",
      weeks: "draft/weeks",
      totalWeeks: "draft/totalWeeks",
    }),
    isEditing() {
      return this.isRenaming || this.isSettingDates;
    },
  },
  watch: {
    draftRef(ref) {
      this.$firebase.setDoc(ref, { viewed: new Date() }, { merge: true });

      if (this.holidayUnsubscribe) this.holidayUnsubscribe();
      const holidayRef = this.$firebase.doc(ref, "pub/holidays");
      this.holidayUnsubscribe = this.$firebase.onSnapshot(
        holidayRef,
        (doc) => {
          if (!doc.exists()) return;
          const holidays = Object.values(doc.get("holidays")).map((ev) => ({
            date: moment(ev.date).toDate(),
            title: ev.title,
          }));
          this.$store.dispatch("draft/setHolidays", holidays);
        },
        (err) => /* istanbul ignore next */ {
          // FIXME: remove this in Vue3
          this.$store.dispatch("alert/error", err.message);
          this.$firebase.log("error", {
            page_location: this.$route.fullPath,
            page_path: this.$route.path,
            err,
          });
          throw err;
        }
      );
    },
    async week(index) {
      try {
        if (index === null || index in this.weeks) return;
        const d = moment(this.range?.beg)
          .startOf("isoWeek")
          .add(index, "weeks");
        const result = await this.$firebase.getDocs(
          this.$firebase.query(
            this.$firebase.collection(this.draftRef, "occurrences"),
            where("date", ">=", d.toDate()),
            where("date", "<=", d.endOf("isoWeek").toDate())
          )
        );
        const week = {};
        result.forEach((doc) => {
          week[doc.id] = Object.assign(doc.data(), {
            date: doc.get("date").toDate(),
          });
        });
        this.$store.dispatch("draft/setWeek", { week, index });
      } catch (err) /* istanbul ignore next*/ {
        // FIXME: remove this in Vue3
        /* istanbul ignore next */
        this.$store.dispatch("alert/error", err.message);
        this.$firebase.log("error", {
          page_location: this.$route.fullPath,
          page_path: this.$route.path,
          err,
        });
        throw err;
      }
    },
  },
  methods: {
    async setTitle(title) {
      const updated = new Date();
      await this.$firebase.setDoc(
        this.draftRef,
        { title, updated },
        { merge: true }
      );
      this.$store.dispatch("draft/setTitle", title);
      this.isRenaming = false;
    },
    async setRange(range) {
      const updated = new Date();
      const batch = this.$firebase.batch();
      batch.set(this.draftRef, { updated }, { merge: true });
      batch.set(this.$firebase.doc(this.draftRef, "pub/range"), {
        range,
        updated,
      });
      await batch.commit();
      this.$store.dispatch("draft/setRange", { ...range });
      this.isSettingDates = false;
      this.$router.replace({ query: { week: 0 } });
    },
    async enableSharing() {
      const updated = new Date();
      await this.$firebase.setDoc(
        this.draftRef,
        { isPublic: true, updated },
        { merge: true }
      );
      this.$store.dispatch("draft/setPublic", true);
    },
    async updateClassSeries(payload, id) {
      id ??= nanoid(12);
      const updated = new Date();
      const template = {};
      template[id] = payload;
      this.$store.dispatch("draft/updateEvent", { id, payload });
      await this.$firebase.setDoc(
        this.draftRef,
        { template, updated },
        { merge: true }
      );
    },
    async deleteClassSeries(id) {
      const updated = new Date();
      const template = {};
      template[id] = deleteField();
      this.$store.dispatch("draft/deleteEvent", id);
      await this.$firebase.setDoc(
        this.draftRef,
        { template, updated },
        { merge: true }
      );
    },
    async setClassInstance(payload) {
      const ref = this.getOccurrenceRef(payload);
      const index = this.getWeekOf(payload.date);
      const updated = new Date();
      if (index in this.weeks) {
        this.$store.dispatch("draft/setOccurrence", {
          data: payload,
          id: ref.id,
          index,
        });
      }
      const batch = this.$firebase.batch();
      batch.set(ref, { ...payload, updated });
      batch.set(this.draftRef, { updated }, { merge: true });
      await batch.commit();
      if (index !== this.week) {
        this.$router.push({ query: { week: index } });
      }
    },
    async unsetClassInstance(payload) {
      const ref = this.getOccurrenceRef(payload);
      const index = this.getWeekOf(payload.date);
      const updated = new Date();
      if (index in this.weeks) {
        this.$store.dispatch("draft/unsetOccurrence", {
          id: ref.id,
          index,
        });
      }
      const batch = this.$firebase.batch();
      batch.delete(ref);
      batch.set(this.draftRef, { updated }, { merge: true });
      await batch.commit();
    },
    getOccurrenceRef({ templateId, date }) {
      return this.$firebase.doc(
        this.draftRef,
        "occurrences",
        `${templateId}:${moment(date).format("YYYYMMDD")}`
      );
    },
    getWeekOf(date) {
      return moment(date).diff(
        moment(this.range.beg).startOf("isoWeek"),
        "weeks"
      );
    },
  },
  destroyed() {
    if (this.holidayUnsubscribe) this.holidayUnsubscribe();
  },
  /* istanbul ignore next */
  async beforeRouteEnter(to, from, next) {
    let draftResult;
    let rangeResult;

    try {
      const baseRef = to.matched.find((r) => r.meta.ref).meta.ref();
      const draftRef = doc(baseRef, "drafts", to.params.draftId);
      draftResult = await getDoc(draftRef);
      if (!draftResult.exists())
        return next({ name: "404", params: [to.path] });
      const rangeRef = doc(draftRef, "pub/range");
      rangeResult = await getDoc(rangeRef);
    } catch (err) {
      next({ name: "500", params: [to.path] });
      throw err;
    }

    return next(async ($vm) => {
      $vm.draftRef = draftResult.ref;
      await $vm.$store.dispatch("draft/setData", draftResult.data());
      if (!rangeResult.exists()) return;
      await $vm.$store.dispatch("draft/setRange", {
        beg: rangeResult.get("range.beg").toDate(),
        end: rangeResult.get("range.end").toDate(),
      });
      const week = parseInt(to.query.week);
      if (isNaN(week) || week < 0) {
        return next({ path: to.path, query: { week: 0 } });
      }
      if (week >= $vm.totalWeeks) {
        return next({ path: to.path, query: { week: $vm.totalWeeks - 1 } });
      }
      $vm.week = week;
    });
  },
  /* istanbul ignore next */
  async beforeRouteUpdate(to, from, next) {
    const baseRef = to.matched.find((r) => r.meta.ref).meta.ref();
    const draftRef = this.$firebase.doc(baseRef, "drafts", to.params.draftId);
    if (!this.$firebase.refEqual(draftRef, this.draftRef)) {
      let draftResult;
      let rangeResult;

      try {
        draftResult = await this.$firebase.getDoc(draftRef);
        if (!draftResult.exists()) {
          return next({ name: "404", params: [to.path] });
        }
        const rangeRef = this.$firebase.doc(draftRef, "pub/range");
        rangeResult = await this.$firebase.getDoc(rangeRef);
      } catch (err) {
        next({ name: "500", params: [to.path] });
        throw err;
      }

      this.draftRef = draftRef;
      await this.$store.dispatch("draft/setData", draftResult.data());
      if (rangeResult.exists()) {
        await this.$store.dispatch("draft/setRange", {
          beg: rangeResult.get("range.beg").toDate(),
          end: rangeResult.get("range.end").toDate(),
        });
      }
    }

    if (this.range) {
      const week = parseInt(to.query.week);
      if (isNaN(week) || week < 0) {
        return next({ path: to.path, query: { week: this.week ?? 0 } });
      }
      if (week >= this.totalWeeks) {
        return next({ path: to.path, query: { week: this.totalWeeks - 1 } });
      }
      this.week = week;
    } else {
      this.week = null;
    }
    return next();
  },
};
</script>
