<template>
  <div class="d-flex">
    <div>
      <v-btn ref="return" icon small @click="$emit('close')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="px-4 text-h6 font-weight-regular" v-text="$t('set dates')" />
    </div>

    <v-menu
      v-model="startDateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          ref="startDateField"
          class="px-2"
          color="grey darken-2"
          dense
          hide-details
          :label="$t('start date')"
          prepend-inner-icon="mdi-calendar"
          solo
          readonly
          :value="startDateText"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        ref="startDatePicker"
        v-model="startDate"
        @input="setStartDate"
        color="primary"
        first-day-of-week="1"
        no-title
      />
    </v-menu>

    <v-menu
      v-model="endDateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          ref="endDateField"
          class="px-2"
          color="grey darken-2"
          dense
          hide-details
          :label="$t('end date')"
          prepend-inner-icon="mdi-calendar"
          solo
          readonly
          :value="endDateText"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        ref="endDatePicker"
        v-model="endDate"
        @input="setEndDate"
        color="primary"
        first-day-of-week="1"
        no-title
      />
    </v-menu>

    <v-btn
      ref="save"
      small
      depressed
      :disabled="disabled"
      color="primary"
      height="38"
      v-text="$t('save')"
      @click="setDateRange"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ScheduleDates",
  props: {
    beg: { type: Date },
    end: { type: Date },
  },
  data: () => ({
    startDateMenu: false,
    startDate: null,
    endDateMenu: false,
    endDate: null,
  }),
  computed: {
    startDateText() {
      return this.startDate
        ? moment(this.startDate).format("ddd, MMM D, YYYY")
        : null;
    },
    endDateText() {
      return this.endDate
        ? moment(this.endDate).format("ddd, MMM D, YYYY")
        : null;
    },
    disabled() {
      return this.startDate === null || this.endDate == null;
    },
  },
  watch: {
    beg: {
      handler(date) {
        if (!date) {
          return;
        }
        this.startDate = moment(date).format("YYYY-MM-DD");
      },
      immediate: true,
    },
    end: {
      handler(date) {
        if (!date) {
          return;
        }
        this.endDate = moment(date).format("YYYY-MM-DD");
      },
      immediate: true,
    },
  },
  methods: {
    setStartDate(date) {
      this.startDateMenu = false;
      const startDate = moment(date);
      if (this.endDate === null || startDate.isAfter(this.endDate, "day")) {
        this.endDate = date;
      }
    },
    setEndDate(date) {
      this.endDateMenu = false;
      const endDate = moment(date);
      if (this.startDate === null || endDate.isBefore(this.startDate, "day")) {
        this.startDate = date;
      }
    },
    setDateRange() {
      const startDate = moment(this.startDate);
      const endDate = moment(this.endDate);

      if (
        this.beg &&
        this.end &&
        startDate.isSame(this.beg, "day") &&
        endDate.isSame(this.end, "day")
      ) {
        this.$emit("close");
        return;
      }
      this.$emit("change", { beg: startDate.toDate(), end: endDate.toDate() });
    },
  },
};
</script>
