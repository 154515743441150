<template>
  <v-select
    hide-details
    :items="items"
    :value="value"
    :loading="loading"
    :menu-props="{ closeOnContentClick: true }"
    style="max-width: 300px"
    :value-comparator="(a, b) => a && b && a.id === b.id"
  >
    <template #selection="{ item }">
      <v-list-item three-line>
        <ScheduleLabel v-bind="item" />
      </v-list-item>
    </template>

    <template #item="{ item }">
      <v-list-item
        three-line
        :data-draft-id="item.id"
        :to="getDraftRoute(item.id)"
      >
        <ScheduleLabel v-bind="item" />
      </v-list-item>
    </template>

    <template #append>
      <v-icon style="top: 24px">mdi-menu-down</v-icon>
    </template>

    <template #append-item>
      <v-divider />
      <v-list-item id="goto-list" :to="listDraftsRoute()">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("see all schedules") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import { limit, orderBy } from "@firebase/firestore";
import _ from "lodash";
import ScheduleLabel from "./ScheduleLabel";
export default {
  name: "ScheduleSelect",
  components: {
    ScheduleLabel,
  },
  data: () => ({
    loading: true,
    draftRef: null,
    routes: null,
    drafts: null,
  }),
  computed: {
    value() {
      return _.pickBy(
        {
          id: this.draftRef?.id,
          title: this.$store.getters["draft/title"],
          range: this.$store.getters["draft/range"],
        },
        _.identity
      );
    },
    items() {
      return [this.value].concat(this.drafts ?? []);
    },
  },
  methods: {
    fetch() {
      const baseRef = this.$route.matched
        .find((r) => r.meta.ref)
        .meta.ref(this.$route);
      this.draftRef = this.$firebase.doc(
        baseRef,
        "drafts",
        this.$route.params.draftId
      );
      this.routes = this.$route.matched
        .find((r) => r.meta.routes)
        .meta.routes(this.$route);
    },
    getDraftRoute(draftId) {
      return this.routes?.getDraft(draftId);
    },
    listDraftsRoute() {
      return this.routes?.listDrafts;
    },
  },
  watch: {
    $route: "fetch",
    async draftRef(curr, prev) {
      if (this.$firebase.refEqual(curr, prev)) return;

      this.loading = true;
      try {
        this.drafts = await this.$firebase
          .getDocs(
            this.$firebase.query(
              curr.parent,
              orderBy("viewed", "desc"),
              limit(5)
            )
          )
          .then((result) =>
            Promise.all(
              result.docs
                .filter((d) => d.id !== curr.id)
                .map(async (d) => {
                  const draft = { id: d.id, title: d.get("title") };
                  const r = await this.$firebase.getDoc(
                    this.$firebase.doc(d.ref, "pub/range")
                  );
                  if (r.exists()) {
                    const beg = r.get("range.beg").toDate();
                    const end = r.get("range.end").toDate();
                    draft.range = { beg, end };
                  }
                  return draft;
                })
            )
          );
      } catch (err) /* istanbul ignore next*/ {
        // FIXME: remove this in Vue3
        this.$store.dispatch("alert/error", err.message);
        this.$firebase.log("error", {
          page_location: this.$route.fullPath,
          page_path: this.$route.path,
          err,
        });
        throw err;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetch();
  },
};
</script>
